<template>
  <section class="products">
    <v-img
      v-if="info.banner"
      :src="info.banner"
      class="tnf-top-banner"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
        v-show="showTab"
      >
        <v-flex style="position: absolute; bottom: 40px;" class="tnf-submenu">
            <v-tabs grow light v-model="categoryTab">
              <v-tab v-for="tab of categorys" :key="tab.key">
                <a :href="'/cases/'+tab.id">{{tab.name}}</a>
              </v-tab>
            </v-tabs>
        </v-flex>
      </v-layout>
    </v-img>
    <v-container  grid-list-md text-center id="news_top" style="margin-bottom:10px">
      <template>
        <div class="container">
          <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
        </div>
      </template>
      <v-container
        fluid
        grid-list-sm
        mt-4
        mb-12
      >
        <v-layout wrap v-if="detail">
          <v-flex xs12 sm12 md12 lg12 mb-4>
            <div class="tnf-center-div">
              <v-carousel
                cycle
                hide-delimiter-background
                hide-delimiters
                show-arrows-on-hover
                :height="sliderHeight"
                width="300"
              >
                <v-carousel-item
                  v-for="(img, ii) in detail.imgs"
                  :key="ii"
                  :src="img.img"
                ></v-carousel-item>
              </v-carousel>
            </div>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <ul v-if="detail.attr" style="list-style: none; padding:0px;">
              <li v-for="(attr, i) in detail.attr" :key="i" style="margin: 20px 0px;">
                <span style="color:#666; font-size:16px;margin-right:60px;">{{attr.attr_name}}:</span>
                <span style="color:#333; font-size:16px;">{{attr.attr_value}}</span>
              </li>
            </ul>
          </v-flex>
          <v-flex mt-4>
            <div class="tnf-center-div">
              <div  v-html="detail.content" class="tnf-case-content">
                  {{detail.content}}
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 3,
    categorys: [],
    category: null,
    categoryTab: null,
    showTab: false,
    detail: null,
    breadcrumbs: [],
    catIds: [],
    catId: null,
    sliderHeight: window.innerWidth > 960 ? 420 : 280
  }),
  created () {
    this.getCase()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banner = val.banner[this.pageId][0].img
        let catIds = []
        let categorys = []
        let cats = val.cases.category.cn
        for (let i in cats) {
          catIds.push(cats[i].id)
          categorys.push(
            { key: i, id: cats[i].id, name: cats[i].name }
          )
        }
        this.catIds = catIds
        this.categorys = categorys
      },
      deep: true
    },
    detail: {
      handler: function (val, oldVal) {
        this.breadcrumbs.push({ text: val.title, disabled: true, href: '' })
        if (this.innerWidth < 550) {
          let imgs = document.querySelectorAll('.tnf-case-content img')
          imgs.forEach((img, i) => {
            img.classList.add('full-img')
          })
        }
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banner = null
      if (this.$store.getters.companyInfo.banner) {
        banner = this.$store.getters.companyInfo.banner[this.pageId][0].img
      }
      return {
        banner: banner
      }
    }
  },
  methods: {
    getCategory () {
      this.https.get('case_category', { id: this.catId }).then(response => {
        if (response.code === 0) {
          this.category = response.data
          if (this.category.cat.banner) {
            this.banner = this.category.cat.banner
          }
          this.parseBreadcrumbs(this.category)
        }
      })
    },
    getCase () {
      this.https.get('case', { id: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.detail = response.data
          document.title = this.detail.title + '_' + 'TRYBA特诺发|法式优雅，德国工艺——缔造完美门窗系统'
          this.catId = this.detail.cid
          this.categoryTab = this.catIds.indexOf(parseInt(this.catId))
          this.showTab = true
          this.getCategory()
        }
      })
    },
    parseBreadcrumbs (category) {
      this.breadcrumbs = [
        { text: '首页', disabled: false, href: '/' }
      ]
      this.breadcrumbs.push({ text: category.cat.name, disabled: false, href: '/cases/' + category.cat.id })
      this.breadcrumbs.push({ text: this.detail.title, disabled: true })
    }
  }
}
</script>
<style lang="css">
  @import '../css/case_detail.css';
</style>
